import { io } from "socket.io-client";

// const URL = "http://localhost:5000";
export const URL = "https://backend.nsmdigirisk.com"
export const WebUrl = "http://localhost:3000";

// const URL = "https://video-call-server-gm7i.onrender.com";

export const socket = io(URL);
export const navbarBrand = "Video Call";
