import React, { useState, useContext, useEffect } from "react";
import { VideoCallContext } from "../../context/Context";
import { MdFileCopy, MdCall, MdOutlineDone } from "react-icons/md";
import { Card, Form, Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./FormCard.css";
import { WebUrl } from "../../config/config";
import { toast } from "react-toastify";
import { AiOutlineMessage } from "react-icons/ai";

const FormCard = () => {
  const [idToCall, setIdToCall] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [userData, setUserData] = useState({});
  const [url, setUrl] = useState(null)

  const handleCopyClick = () => {
    if (!name) {
      toast.info(`Please enter your name`, {
        icon: <AiOutlineMessage className="notification-message" size={30} />,
      });
    }
    else {

      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

  };

  const handleCall = () => {
    if (!name) {
      toast.info(`Please enter your name`, {
        icon: <AiOutlineMessage className="notification-message" size={30} />,
      });
    }
    else {
      callUser(idToCall)
    }
  }


  const { name, setName, myUserId, callUser, isCallAccepted } = useContext(VideoCallContext);

  // Fetch code from URL and set as idToCall if available
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUrl(window?.location?.origin)
    const code = urlParams.get("code");
    if (code) {
      setIdToCall(code);
    }

    const userDetails = JSON?.parse(localStorage.getItem('videoCallUserDetails')) ?? {};
    setUserData(userDetails ?? {});
    if (userDetails && userDetails?.name) {
      setName(userDetails?.name)
    }
  }, []);



  return (
    <>
      {!isCallAccepted && (
        <div className="form-section">
          <Card className="card">
            <Form noValidate autoComplete="off">
              <Form.Group controlId="name">
                <Form.Label className="form-label">Your Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  className="form-input"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                />
              </Form.Group>
              {(!userData?.name && idToCall) ?
                <></>
                :
                <CopyToClipboard text={`${url}/home?code=${myUserId}`}>
                  <Button
                    className="form-main-btn mb-3"
                    onClick={handleCopyClick}
                  >
                    {isCopied ? (
                      <>
                        <MdOutlineDone size={22} />Wait Link Copying...!
                      </>
                    ) : (
                      <>
                        <MdFileCopy size={22} /> Generate Meeting
                      </>
                    )}
                  </Button>
                </CopyToClipboard>
              }
              {
                userData?.name ?
                  <></>
                  :
                  <>
                    <Form.Group controlId="idToCall">
                      <Form.Label className="form-label">ID to call</Form.Label>
                      <Form.Control
                        type="text"
                        value={idToCall}
                        className="form-input"
                        onChange={(e) => setIdToCall(e.target.value)}
                        placeholder="Enter the ID to make a call"
                      />
                    </Form.Group>
                    <Button
                      className="form-main-btn"
                      onClick={handleCall}
                    >
                      <MdCall size={22} /> Call
                    </Button>
                  </>
              }

            </Form>
          </Card>
        </div>
      )}
    </>
  );
};

export default FormCard;
