import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Video from "../components/Video/Video";
import FormCard from "../components/FormCard/FormCard";
import IncomingCall from "../components/IncomingCall/IncomingCall";
import { VideoCallProvider } from "../context/Context";
import "../components/Video/Video.css";


const Home = () => {
  return (
    <>
      <VideoCallProvider>
        <NavBar />
        <div className="main">
          <Video />
          <FormCard />
        </div>
        <IncomingCall />
      </VideoCallProvider>
    </>
  );
};

export default Home;