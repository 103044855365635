import React from 'react'
import "../../src/page/Login.css"

const Loader = () => {
    return (
        <div className="loader-overlay">
            <div className="loader-spinner"></div>
        </div>
    )
}

export default Loader