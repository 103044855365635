
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bg from "../assets/loginBg.png";
import { faEye, faEyeSlash, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import "./Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { URL } from "../config/config";

export default function Login() {
    const [togglePassword, setTogglePassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const serverUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate()
    //Login
    const login = async (data) => {
        try {
            setLoader(true);
            const serverResponse = await axios.post(`${URL}/user/login`, { ...data }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (serverResponse?.data?.status === "SUCCESS") {
                localStorage.setItem('authToken', serverResponse?.data?.token);
                localStorage.setItem('videoCallUserDetails', JSON.stringify(serverResponse?.data?.userDetails));
                // setCookie("NSMVIDEOCALL", serverResponse?.data?.token);
                // setCookie("videoCallUserDetails", serverResponse?.data?.userDetails);
                Swal.fire({ text: serverResponse?.data?.message, icon: "success", timer: 2000 });
                navigate("/home");
            } else {
                Swal.fire({ text: serverResponse?.data?.message, icon: "warning" });
            }
            setLoader(false);
        } catch (error) {
            Swal.fire({ text: error?.response?.data?.message || error.message, icon: "warning", });
            setLoader(false);
        }
    }


    return (
        <>
            {loader === true ?
                <Loader />
                :
                <div className="login_wrapper" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="login_card_wrapper">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="company_name">
                                    <h4>NSM <br /> & <br /> Associate</h4>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <div className="login_form_wrapper">
                                    <div className="input_box_wrapper">
                                        <label className="login_input_label">User ID</label>
                                        <div className="input_wrapper">
                                            <FontAwesomeIcon icon={faUser} className="input_icon" />
                                            <input type="text" className="form-control"
                                                {...register("userId", {
                                                    required: "userId is required",
                                                })}
                                            />
                                        </div>
                                        {errors.userId && (
                                            <div className="error-message">
                                                <p
                                                    className="text-danger text-start"
                                                >
                                                    {errors.userId.message}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="input_box_wrapper">
                                        <label className="login_input_label">Password</label>
                                        <div className="input_wrapper">
                                            <FontAwesomeIcon icon={faLock} className="input_icon" />
                                            <input type={togglePassword ? "text" : "password"} className="form-control"
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters",
                                                    },
                                                    maxLength: {
                                                        value: 16,
                                                        message: "Password can not be greater than 16 characters",
                                                    },
                                                })}
                                            />
                                            <FontAwesomeIcon onClick={() => setTogglePassword(!togglePassword)} icon={togglePassword ? faEye : faEyeSlash} className="password_toggler_icon" />

                                        </div>

                                        {errors.password && (
                                            <div className="error-message">
                                                <p
                                                    className="text-danger text-start"
                                                >
                                                    {errors.password.message}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="login_button_wrapper">
                                        <button onClick={handleSubmit(login)} type="submit">Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
